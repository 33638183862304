import React, { useContext, useEffect } from 'react';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import { CmsPageContext } from '../../shared/cmsPage/definitions';
import useItemEditActions from '../../shared/cmsPage/hooks/useItemEditActions';
import useLoadEditorItem from '../../shared/cmsPage/hooks/useLoadEditorItem';
import AudienceSelector from '../../shared/form/components/AudienceSelector';
import FormInput from '../../shared/form/components/FormInput';
import LinkFinderMenu from '../../shared/form/components/LinkFinderMenu';
import { useCmsForm } from '../../shared/form/hooks/useCmsForm';
import { useSpinner } from '../../shared/spinner/hooks/useSpinner';
import menuItemApi from '../api/navbarItemApi';
import { NavbarEditorConstants } from '../definitions';

const MenuItemEditor = (props) => {
  const { itemId, menuId, growlId, onItemChange } = props;
  const { spinnerProvider } = useContext(CmsPageContext);
  const { item, loadItem, saveItem, requestPending } = useItemPersistence(menuItemApi, menuId);
  const form = useCmsForm(item);
  const { setValue, watch } = form;
  const actions = useItemEditActions(saveItem, form, NavbarEditorConstants, menuId);
  const defaultPage = 'cms://default';
  useSpinner(spinnerProvider, requestPending);
  useLoadEditorItem(itemId, loadItem, onItemChange, item, NavbarEditorConstants);

  const disLabel = watch('displayLabel');

  useEffect(() => {
    if (item) {
      if (
        typeof item.objectReference === 'string' &&
        item.objectDetail != null &&
        item.objectDetail.plugin != 'default'
      ) {
        setValue('displayLabel', item.objectDetail.displayName || item.objectDetail.plugin);
      }
      if (typeof item.objectReference === 'string' && item.objectDetail == null) {
        setValue('displayLabel', 'cms://default');
        setValue('objectReference', 'cms://default');
      }
    }
  }, [item]);

  useEffect(() => {
    if (item && typeof disLabel === 'object') {
      setValue('displayLabel', disLabel.targetLabel);
      setValue('objectReference', disLabel.returnVal);
    }
  }, [disLabel]);

  return (
    <>
      <div className="row">
        <form role="form">
          <FormInput
            label="Title (Label)"
            fieldName="label"
            formContext={form}
            formFieldOptions={{
              pattern: {
                value: /[\w,./=?-]+/,
                message: 'This field cannot be white space.',
              },
              required: 'This field is required.',
            }}
          />
          <LinkFinderMenu
            formContext={form}
            label="Link Item to"
            fieldName="displayLabel"
            defaultValue={defaultPage}
            allowEmbeddedMenus={false}
            required={true}
          />
          <AudienceSelector
            formContext={form}
            label="Available To "
            fieldName="condition"
            growlId={growlId}
          />
        </form>
      </div>
      <div className="row module-footer">
        <CmsPageSectionFooter actions={actions} />
      </div>
    </>
  );
};

export default MenuItemEditor;
