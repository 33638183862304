import get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CmsContext } from '../../context/definitions';
import { useItemPersistence } from '../../shared/cms/hooks/useItemPersistence';
import { useListLoader } from '../../shared/cms/hooks/useListLoader';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import CmsSubItemListSection from '../../shared/cmsPage/components/CmsSubItemListSection';
import { useEditPageTitle } from '../../shared/cmsPage/hooks/useEditPageTitle';
import useSubItemListActions from '../../shared/cmsPage/hooks/useSubItemListActions';
import ItemLinkDisplay from '../../shared/itemTable/components/ItemLinkDisplay';
import ItemPublishStatus from '../../shared/itemTable/components/ItemPublishStatus';
import Timestamp from '../../shared/itemTable/components/Timestamp';
import useArray from '../../shared/util/hooks/useArray';
import navbarApi from '../api/navbarApi';
import navbarItemApi from '../api/navbarItemApi';
import { NavbarEditorConstants } from '../definitions';
import useNavbarEditorActions from '../hooks/useNavbarEditorActions';
import NavbarItemTitle from './NavbarItemTitle';

const NavigationEditorPage = (props) => {
  const { collectionId: propCollectionId } = props;

  const [hasReordered, setHasReordered] = useState(false);

  const [collectionId, setCollectionId] = useState(propCollectionId || '');
  const context = useContext(CmsContext);
  const isDefaultSubtenant = get(context, 'selectedSubtenant.isDefault', true);
  useEffect(() => {
    if (!isDefaultSubtenant) {
      setCollectionId('');
      return;
    }
    const getCollections = async () => {
      const { records } = await navbarApi.loadRecords({ sort: {} });
      if (records && records.length) setCollectionId(records[0].id);
    };
    if (propCollectionId) setCollectionId(propCollectionId);
    else {
      getCollections();
    }
  }, [propCollectionId, isDefaultSubtenant]);

  const CollectionItemColumns = [
    {
      label: 'Title (Label)',
      renderer: (item) => <NavbarItemTitle collectionItem={item} collectionId={collectionId} />,
      valueField: 'label',
    },
    {
      align: 'center',
      label: 'Status',
      renderer: (item) => <ItemPublishStatus status={item.status} />,
      valueField: 'status',
    },
    {
      align: 'center',
      label: 'Type',
      renderer: (item) => <div>{_.capitalize(item?.objectDetail?.plugin)}</div>,
      valueField: 'objectReference',
    },
    {
      align: 'center',
      label: 'Link',
      renderer: (item) => (
        <ItemLinkDisplay objectReference={item.objectReference} objectDetail={item.objectDetail} />
      ),
      valueField: 'objectReference',
    },
    {
      align: 'center',
      label: 'Last Modified',
      renderer: (item, field) => <Timestamp date={item[field]} />,
      sortable: true,
      valueField: 'lastUpdated',
    },
  ];

  const {
    add,
    edit,
    restore,
    requestPending: itemActionRequestPending,
  } = useSubItemListActions(navbarItemApi, collectionId, NavbarEditorConstants);

  const {
    item,
    loadItem,
    requestPending: loadItemPending,
    reorderItems,
  } = useItemPersistence(navbarApi);

  const reorderItemsWrapper = useCallback(
    async (parentId, itemIds) => {
      setHasReordered(true);
      await reorderItems(parentId, itemIds);
    },
    [setHasReordered, reorderItems]
  );

  useEffect(() => {
    if (collectionId) (async () => await loadItem(collectionId))();
  }, [collectionId, loadItem]);

  const emptyListMessage = useMemo(
    () =>
      item
        ? `There are currently no collection items for '${item.name}.'`
        : `There are currently no collection items.`,
    [item]
  );

  const { loadRecords } = navbarItemApi;
  const { loadItems, records } = useListLoader(loadRecords);
  const requestPending = itemActionRequestPending || loadItemPending;
  const { onItemChange } = useEditPageTitle(collectionId, 'Collection', 'name');
  const pageTitle = 'NavBar and Home';
  useEffect(() => onItemChange(item), [item, onItemChange]);
  const hasItemsPendingPublish = records.some((r) => r && r.status && r.status !== 'published');
  const hasSettingsPendingPublish = item && item.status && item.status !== 'published';

  const loaderParams = useMemo(() => ({ parentItemId: collectionId }), [collectionId]);
  const { publish, remove, reset } = useNavbarEditorActions({
    collectionId,
    hasItemsPendingPublish,
    loadItem,
    loadItems,
    pageTitle,
    publishDisabled,
    setHasReordered,
    loaderParams,
  });

  const toolbarActions = useArray(publish, add, reset);
  const itemActions = useArray(edit, remove, restore);

  const orientHandler = useCallback(
    async (e) => {
      const { value } = e.target;
      const updatedItem = { ...item, orientation: value };
      await navbarApi.saveRecord(updatedItem);
      setHasReordered(true);
    },
    [item, navbarApi]
  );
  const publishDisabled = !hasSettingsPendingPublish && !hasItemsPendingPublish && !hasReordered;
  const alert = {
    alertType: 'info',
    condition: !publishDisabled,
    message: `You have unpublished changes to the NavBar which may include orientation or sort order. The first table row, with house icon will be set as home page. Select Publish Collection to begin the publish process.`,
  };

  const priFilter = useCallback((i) => i.isHomePage, []);
  return (
    <div id="navBarEditorPage">
      <CmsPage title={pageTitle} requestPending={requestPending || !collectionId}>
        {isDefaultSubtenant && (
          <>
            <div className="neg-left-margin-10">
              {item && (
                <label>
                  Select Orientation:
                  <select
                    defaultValue={item.orientation}
                    onChange={orientHandler}
                    className="orientation-select"
                  >
                    <option value="horizontal">Top/Horizontal</option>
                    <option value="vertical">Side/Vertical</option>
                  </select>
                </label>
              )}
            </div>
            {!!collectionId && (
              <CmsSubItemListSection
                pageId={NavbarEditorConstants.defaultPageId}
                parentItemId={collectionId}
                parentPageTitle="Collections"
                className="nav-manager"
                toolbarActions={toolbarActions}
                itemActions={itemActions}
                reorder={reorderItemsWrapper}
                emptyListMessage={emptyListMessage}
                columns={CollectionItemColumns}
                subItemApi={navbarItemApi}
                pageConstants={NavbarEditorConstants}
                alert={alert}
                priorityFilter={priFilter}
                showBreadCrumb={false}
              />
            )}
          </>
        )}
        {!isDefaultSubtenant && (
          <div className="alert alert-info">
            To edit the NavBar, the default subtenant must be selected. To add items to the
            subtenant collection on the NavBar, select &ldquo;Collections&rdquo; from the collection
            on the left, and edit the Collection that shares a name with the subtenant.
          </div>
        )}
      </CmsPage>
    </div>
  );
};

export default NavigationEditorPage;
